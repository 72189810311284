/* eslint-disable no-unused-vars */
/* eslint-disable react/no-danger */
/* eslint-disable max-len, no-nested-ternary */
import React, { useState } from 'react';
import { Button, Card } from 'antd';
import {
  ArrowLeftOutlined,
  CheckCircleOutlined,
  CheckOutlined,
  CloseOutlined,
  SyncOutlined,
} from '@ant-design/icons';
import { connect } from 'react-redux';
import { platforms } from '@/api';
import GorgiasConnect from '@/base-components/integrations/components/GorgiasConnect';
import { displayOrCatchMessage } from '@/helpers';
import {
  getGraphic,
  getName,
  getSubType,
  getDescription,
  getH2,
  isLoading,
} from './IntegrationCard';
import useIntegrationsStore from '../store/useIntegrationsStore';
import config from '../../../config';
import ZendeskConnect from './ZendeskConnect';
import ZapierConnect from './ZapierConnect';
import TypeformConnect from './TypeformConnect';
import CsvUploadButton from './csv';
import CSVConntected from './CSVConntected';
import CrawlerConnect from './CrawlerConnect';
import TwitterConnect from './TwitterConnect';

const renderConnectButton = (type, onConnect, popupOpened) => {
  switch (type) {
    case 'typeform':
    case 'intercom':
      return (
        <Button
          onClick={onConnect}
          size="large"
          type="primary"
          disabled={popupOpened}
        >
          {popupOpened ? 'Connecting...' : 'Connect'}
        </Button>
      );
    case 'chat_feedback_upload':
    case 'survey_upload':
    case 'review_upload':
      return (
        <CsvUploadButton type={type} />
      );
    default:
      return null;
  }
};

const renderExtendedContent = (
  type,
  popupOpened,
  setPopupOpened,
  updateConnectedPlatforms,
  user,
) => {
  switch (type) {
    case 'zendesk':
      return (
        <ZendeskConnect
          popupOpened={popupOpened}
          setPopupOpened={setPopupOpened}
          updateConnectedPlatforms={updateConnectedPlatforms}
          user={user}
        />
      );
    case 'gorgias':
      return (
        <GorgiasConnect
          popupOpened={popupOpened}
          setPopupOpened={setPopupOpened}
          updateConnectedPlatforms={updateConnectedPlatforms}
          user={user}
        />
      );
    case 'zapier':
      return <ZapierConnect />;
    case 'webscan':
      return (
        <CrawlerConnect />
      );
    case 'twitter':
      return <TwitterConnect />;
    default:
      return null;
  }
};

const renderConnected = (type) => {
  switch (type) {
    case 'typeform':
      return <TypeformConnect />;
    case 'chat_feedback_upload':
      return <CSVConntected />;
    default:
      return (
        <div className="integrations__extended_card_connected">
          <CheckOutlined />
          <span className="integrations__extended_card_connected_text">
            Connected
          </span>
        </div>
      );
  }
};

const isConnected = (type, state) => {
  switch (type) {
    case 'zendesk':
    case 'gorgias':
    case 'intercom':
    case 'typeform':
      return state.connectedPlatforms.map((p) => p.type).includes(type);
    case 'chat_feedback_upload':
    case 'survey_upload':
    case 'review_upload':
      return state.csvFiles.length > 0;
    default:
      return false;
  }
};

const IntegrationExtendedCard = ({ type, user, enableToDisconnect }) => {
  const [uploading, setUploading] = useState(false);
  const [popupOpened, setPopupOpened] = useState(false);
  const [disconnectRequested, setDisconnectRequested] = useState(false);
  const {
    state,
    actions: {
      highlightNone,
      updateConnectedPlatforms,
      updateCsvFiles,
      csvUploaded,
      reconnectPlatform,
    },
  } = useIntegrationsStore();
  const connected = isConnected(type, state);
  const loading = isLoading(type, state);
  const platform = state.connectedPlatforms.find((p) => p.type === type);

  const onConnectIntercom = () => {
    setPopupOpened(true);
    const myWindow = window.open(
      `${config.apiBaseUrl}/intercom/auth?token=${user.token}&skip-onboarding=1`,
      'logw',
      'width=400,height=630',
    );
    const timer = setInterval(() => {
      if (myWindow.closed) {
        clearInterval(timer);
        setPopupOpened(false);
        updateConnectedPlatforms(true);
      }
    }, 1000);
  };

  const onConnectTypeform = () => {
    setPopupOpened(true);
    const myWindow = window.open(
      `${config.apiBaseUrl}/typeform/auth?token=${user.token}`,
      'logw',
      'width=400,height=630',
    );
    const timer = setInterval(() => {
      if (myWindow.closed) {
        clearInterval(timer);
        setPopupOpened(false);
        updateConnectedPlatforms(true);
      }
    }, 1000);
  };

  const onConnect = () => {
    switch (type) {
      case 'intercom':
        onConnectIntercom();
        break;
      case 'typeform':
        onConnectTypeform();
        break;
      default:
        break;
    }
  };

  const onDisconnectIntercom = () => {
    setDisconnectRequested(true);
    platforms
      .disconnectIntercom()
      .then(() => {
        updateConnectedPlatforms(true);
        setDisconnectRequested(false);
      })
      .catch((e) => displayOrCatchMessage(e));
  };

  const onDisconnectTypeform = () => {
    setDisconnectRequested(true);
    platforms
      .disconnectTypeform()
      .then(() => {
        updateConnectedPlatforms(true);
        setDisconnectRequested(false);
      })
      .catch((e) => displayOrCatchMessage(e));
  };

  const onDisconnectZendeskSupport = () => {
    setDisconnectRequested(true);
    platforms
      .disconnectZendeskSupport()
      .then(() => {
        updateConnectedPlatforms(true);
        setDisconnectRequested(false);
      })
      .catch((e) => displayOrCatchMessage(e));
  };

  const onDisconnectGorgias = () => {
    setDisconnectRequested(true);
    platforms
      .disconnectGorgias()
      .then(() => {
        updateConnectedPlatforms(true);
        setDisconnectRequested(false);
      })
      .catch((e) => displayOrCatchMessage(e));
  };

  const onDisconnect = () => {
    switch (type) {
      case 'intercom':
        onDisconnectIntercom();
        break;
      case 'zendesk':
        onDisconnectZendeskSupport();
        break;
      case 'gorgias':
        onDisconnectGorgias();
        break;
      case 'typeform':
        onDisconnectTypeform();
        break;
      default:
        break;
    }
  };

  const onReconnect = () => {
    switch (type) {
      case 'intercom':
      case 'zendesk':
      case 'gorgias':
      case 'typeform':
        reconnectPlatform(type);
        break;
      default:
        break;
    }
  };

  const { header, content } = state.highlightedPlatform === 'custom'
    ? state.highlightedCustomComponent || {}
    : {};

  return (
    <div className="integrations__extended_card_wrap">
      <Button
        onClick={highlightNone}
        className="integrations__extended_card_wrap_back"
        size="large"
        type="ghost"
      >
        <ArrowLeftOutlined />
      </Button>
      <Card className="integrations__extended_card">
        <div className="integrations__extended_card_header">
          {header || (
            <>
              <div className="integrations__extended_card_identity">
                <div>{getGraphic(type)}</div>
                <div className="integrations__extended_card_name !ml-3">
                  <h4>{getName(type)}</h4>
                  <h5>{getSubType(type)}</h5>
                </div>
              </div>
              <div>
                {connected ? (
                  <>
                    {enableToDisconnect ? (
                      <Button
                        onClick={onDisconnect}
                        size="large"
                        type="link"
                        disabled={disconnectRequested}
                      >
                        <CloseOutlined />
                        {' '}
                        Disconnect
                      </Button>
                    ) : (
                      <>
                        {type !== 'chat_feedback_upload' ? (
                          <div className="gordita-medium-1 flex flex-center">
                            <Button
                              onClick={onReconnect}
                              size="large"
                              type="link"
                              disabled={disconnectRequested}
                            >
                              <SyncOutlined />
                              {' '}
                              Reconnect
                            </Button>
                            <Button
                              onClick={onDisconnect}
                              size="large"
                              type="link"
                              disabled={disconnectRequested}
                            >
                              <CloseOutlined />
                              {' '}
                              Disconnect
                            </Button>
                            {
                              platform.status !== 'not_ready' ? (
                                <>
                                  <CheckCircleOutlined className="mr-xs ml-4" />
                                  Connected
                                </>
                              ) : null
                          }
                          </div>
                        ) : (
                          renderConnectButton(
                            type,
                            onConnect,
                            popupOpened,
                            uploading,
                            setUploading,
                            updateCsvFiles,
                            loading,
                            csvUploaded,
                          )
                        )}

                      </>
                    )}
                  </>
                ) : (
                  renderConnectButton(
                    type,
                    onConnect,
                    popupOpened,
                    uploading,
                    setUploading,
                    updateCsvFiles,
                    loading,
                    csvUploaded,
                  )
                )}
              </div>
            </>
          )}
        </div>
        <div className="integrations__extended_card_body">
          {content || (
            <>
              {loading ? (
                <div className="integrations__extended_card_connected">
                  <div className="integrations__loader" />
                  <span className="integrations__extended_card_connected_text">
                    Gathering and processing data...
                  </span>
                </div>
              ) : connected ? (
                renderConnected(type)
              ) : (
                <>
                  <h2>{getH2(type)}</h2>
                  <p
                    dangerouslySetInnerHTML={{ __html: getDescription(type) }}
                  />
                  {renderExtendedContent(
                    type,
                    popupOpened,
                    setPopupOpened,
                    updateConnectedPlatforms,
                    user,
                  )}
                </>
              )}
            </>
          )}
        </div>
      </Card>
    </div>
  );
};

const mapStateToProps = (state) => ({
  user: state.user,
});

export default connect(mapStateToProps, null)(IntegrationExtendedCard);
