import React from 'react';
import { Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import auth from '@/api/auth';
import posthog from 'posthog-js';
import ProtectedRoute from '../auth/ProtectedRoute';

const OnboardedRoute = ({ children, user, ...props }) => {
  const team = user ? auth.team() : null;

  if (user && team.owner && (!team.dashboard_ready && !posthog.isFeatureEnabled('skip-onboarding') && !user.email.endsWith('optymous.com'))) {
    return (
      <Redirect push to="/onboarding" />
    );
  }

  return (
    <ProtectedRoute {...props}>
      {children}
    </ProtectedRoute>
  );
};

const mapStateToProps = (state) => ({
  user: state.user,
});

export default connect(mapStateToProps)(OnboardedRoute);
